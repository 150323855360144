import styled from 'styled-components';
import { themeOptions } from 'assets/styles/theme';

interface InputProps {
  color?: string;
  weight?: number;
}

export const Input = styled.p<InputProps>`
  color: ${(props) => props.color || themeOptions.palette.common.black};
  font-size: 15px;
  font-weight: ${(props) => props.weight || 500};
  white-space: nowrap;
  overflow-x: hidden; 
  text-overflow: ellipsis;
  max-width: 100%;

  a {
    color: #0070D8;
    text-decoration: underline;
    font-size: 16px;
  }
`;