/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button as MUIButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useRef, useState } from 'react';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import cancelIcon from 'assets/icons/cancel.png';
import { blobToBase64, getCompressImageBase64 } from 'utils/shared/image';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { themeOptions } from 'assets/styles/theme';
import { RemoveCircle } from '@mui/icons-material';
import {
  Container,
  Label,
  ImagePreview,
  Buttons,
  ProgressContainer,
  MaxSizeTextError,
  FilePreview,
} from './styles';
// Max Size is 5MB
const MAX_FILE_SIZE = 5127304;

interface UploadFileProps {
  setValue: (value: string) => void;
  defaultValue: string;
}

export default function UploadFile({
  setValue,
  defaultValue,
}: UploadFileProps) {
  const { t } = useTranslation();
  const [filePreview, setFilePreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setFilePreview(defaultValue);
  }, [defaultValue]);

  const modalRef = useRef<ImperativeModalHandler>(null);

  const handleOpenModal = () => modalRef.current?.open();

  const handleCloseModal = () => modalRef.current?.close();

  const handleRemoveFile = () => {
    handleCloseModal();
    setFilePreview('');
    setValue('');
  };

  const handleFilePreview = async (e: any) => {
    const { target } = e;
    handleRemoveFile();
    const file = target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      setError(true);
    } else {
      setError(false);
      setIsLoading(true);
      const fileUrl = URL.createObjectURL(file);

      if (file.type.startsWith('image/')) {
        const base64 = await getCompressImageBase64(file, setPercentage);
        setValue(base64);
      } else {
        const base64 = await blobToBase64(file);
        setValue(base64 as string);
      }

      setFilePreview(fileUrl);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {error && (
        <MaxSizeTextError>{t('max_image_size_error_text')}</MaxSizeTextError>
      )}
      {filePreview.startsWith('data:image/') && (
        <ImagePreview image={defaultValue}>
          <img src={cancelIcon} alt="remove icon" onClick={handleOpenModal} />
        </ImagePreview>
      )}
      {filePreview.startsWith('data:application/pdf') && (
        <FilePreview>
          <div>
            <p className="contentName">{t('fileUploaded')}</p>
            <CheckCircle
              style={{
                color: themeOptions.palette.secondary.light,
              }}
            />
          </div>
        </FilePreview>
      )}

      {isLoading && (
        <ProgressContainer>
          <ProgressBar step={percentage} totalSteps={100} />
        </ProgressContainer>
      )}
      <button type="button" />
      <Label htmlFor="contained-button-file" marginTop={!filePreview ? 70 : 0}>
        <input
          type="file"
          accept="image/*,.pdf"
          id="contained-button-file"
          onChange={handleFilePreview}
        />
        <MUIButton
          variant="outlined"
          component="span"
          endIcon={<FileUploadIcon />}
        >
          {t('send_file')}
        </MUIButton>
        {filePreview.startsWith('data:application/pdf') && (
          <div>
            <Button
              color="error"
              variant="text"
              rightIcon={
                <div style={{ marginLeft: '10px' }}>
                  <RemoveCircle />
                </div>
              }
              onClick={handleOpenModal}
            >
              {t('remove')}
            </Button>
          </div>
        )}
      </Label>

      <Modal
        ref={modalRef}
        title={t('remove_file_title')}
        subtitle={t('remove_image_message')}
      >
        <Buttons>
          <Button onClick={handleCloseModal}>{t('cancel')}</Button>
          <Button
            variant="contained"
            color="error"
            rightIcon={<DeleteIcon />}
            onClick={handleRemoveFile}
          >
            {t('remove_file')}
          </Button>
        </Buttons>
      </Modal>
    </Container>
  );
}
