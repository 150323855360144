import { Answer, Field } from 'store/states/forms/types';
import { FieldBoxWithImage } from './FieldBoxWithImage';
import { FieldBox } from './FieldBox';

interface IFieldBox {
  field: Field;
  answer: Answer;
  index?: number;
  handleEdit: () => void;
}

export function FieldBoxComponent({
  field,
  answer,
  index,
  handleEdit,
}: IFieldBox) {
  if (field.type === 'checkbox' && answer.value) {
    const multiAnswers = answer.value.split('\t').slice(1);
    const formatedAnswer = multiAnswers
      .map(
        (multiAnswer) =>
          field.items?.find(
            (item) => (item.value as unknown as string) === multiAnswer,
          )?.label ?? multiAnswer,
      )
      .join('; ');
    return (
      <FieldBox
        field={field}
        answer={{ field_id: answer.field_id, value: formatedAnswer }}
        index={index}
        handleClickEdit={handleEdit}
      />
    );
  }
  switch (field.type) {
    case 'signature':
      return (
        <FieldBoxWithImage
          field={field}
          answer={answer}
          index={index}
          handleClickEdit={handleEdit}
        />
      );
    case 'draw':
      return (
        <FieldBoxWithImage
          field={field}
          answer={answer}
          index={index}
          handleClickEdit={handleEdit}
        />
      );
    case 'image':
      return (
        <FieldBoxWithImage
          field={field}
          answer={answer}
          index={index}
          handleClickEdit={handleEdit}
        />
      );
    default:
      return (
        <FieldBox
          field={field}
          answer={answer}
          index={index}
          handleClickEdit={handleEdit}
        />
      );
  }
}