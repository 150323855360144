import { ComponentPropsWithoutRef, ReactNode } from 'react';

import { Button as ButtonMui, CircularProgress } from '@mui/material';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: 'text' | 'outlined' | 'contained';
  isLoading?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

}

export function Button({
  children, variant = 'outlined', isLoading = false, leftIcon, rightIcon, color, ...rest
}: ButtonProps) {
  return isLoading ? (
    <ButtonMui size='large' disabled color={color} variant={variant} {...rest}>
      <span>{children}</span>
      <CircularProgress size={16} color='inherit' style={{ marginLeft: '40px' }} />
    </ButtonMui>

  ) : (
    <ButtonMui size='large' color={color} variant={variant} {...rest}>
      {leftIcon}
      <span>{children}</span>
      {rightIcon}
    </ButtonMui>
  );
}
