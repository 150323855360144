import {
  Divider,
  styled,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  IconButton,
  Box,
} from '@mui/material';
import { themeOptions } from 'assets/styles/theme';
import { t } from 'i18next';
import React, { useState } from 'react';
import { List, ListRowRenderer } from 'react-virtualized';
import { Field, Item } from 'store/states/forms/types';
import CancelIcon from '@mui/icons-material/Cancel';

interface RadioBoxProps {
  setValue: (value: string) => void;
  defaultValue: string;
  onCustomItemAdded: (items: Item) => void;
  onCustomItemRemoved: (items: Item) => void;
  field: Field;
}

const StyledFomControlLabel = styled(FormControlLabel)`
  color: ${themeOptions.palette?.text?.primary};
  width: 100%;
`;

export default function RadioBox({
  setValue,
  defaultValue,
  onCustomItemAdded,
  onCustomItemRemoved,
  field,
}: RadioBoxProps) {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    const value =
      typeof event === 'string'
        ? event
        : (event.target as HTMLInputElement).value;
    if (value === defaultValue) {
      setValue('');
    } else {
      setValue(value);
    }
  };
  const [newItemValue, setNewItemValue] = useState<string>('');

  const items = field.items || [];

  const canMoreOptionsBeAdded = () => {
    const allowIncludeMoreOptions = field.rules?.allowIncludeMoreOptions;
    const limitIncludeMoreOptions = field.rules?.limitIncludeMoreOptions;

    if (!allowIncludeMoreOptions) return false;

    if (limitIncludeMoreOptions) {
      const customItems = items.filter((item) => item.isCustom);
      return customItems.length < limitIncludeMoreOptions;
    }

    return false;
  };

  const radioItems = !canMoreOptionsBeAdded()
    ? items
    : [
        ...items,
        {
          value: newItemValue || 'other',
          label: (
            <TextField
              value={newItemValue}
              sx={{
                width: '175%',
              }}
              onChange={(event) => setNewItemValue(event.target.value)}
              disabled={false}
              label={t('others')}
              placeholder={t('digitHere')}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyUp={(event) => {
                const value = newItemValue?.trim();
                if (event.key === 'Enter' && value) {
                  onCustomItemAdded({
                    label: value,
                    value,
                    id: value,
                    isCustom: true,
                    name: value,
                  });
                  handleChange(value);
                  setNewItemValue('');
                }
              }}
            />
          ),
        },
      ];

  const arrayLastItem = radioItems && radioItems.length - 1;

  const getRowHeight = ({ index }: { index: number }) => {
    if (index === arrayLastItem) {
      return 80;
    }
    return 55;
  };

  const rowCustomAdded = (item: Item) => ({
    value: item.value,
    label: (
      <TextField
        sx={{
          input: {
            cursor: 'pointer',
          },
          width: '145%',
        }}
        value={item.value}
        onChange={(event) => setNewItemValue(event.target.value)}
        disabled={false}
        label={t('other')}
        onClick={() => handleChange(item.value)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton onClick={() => onCustomItemRemoved(item)}>
              <CancelIcon
                style={{
                  color: themeOptions.palette.grey[400],
                  cursor: 'pointer',
                }}
              />
            </IconButton>
          ),
        }}
      />
    ),
  });

  const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
    const currentItem = field.items[index] || {};
    const customRow = currentItem.isCustom && rowCustomAdded(currentItem);

    return (
      <div key={key} style={style}>
        {currentItem.isCustom && customRow && (
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <StyledFomControlLabel
              value={customRow.value}
              control={<Radio />}
              label={customRow.label}
            />
          </div>
        )}
        {radioItems[index] && !currentItem.isCustom && (
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              marginTop: React.isValidElement(radioItems[index]?.label)
                ? '10px'
                : 0,
            }}
          >
            <StyledFomControlLabel
              value={radioItems[index]?.value}
              control={
                <Radio
                  disabled={React.isValidElement(radioItems[index]?.label)}
                />
              }
              label={radioItems[index]?.label}
            />
          </Box>
        )}
        {index !== arrayLastItem && <Divider />}
      </div>
    );
  };

  return (
    <FormControl
      sx={{ width: 'inherit' }}
      style={{ height: '100%', overflowY: 'auto' }}
      component="fieldset"
    >
      <RadioGroup
        onChange={handleChange}
        value={defaultValue}
        name="radio-buttons-group"
      >
        <List
          width={400}
          height={290}
          overscanRowCount={3}
          rowCount={radioItems.length}
          rowHeight={getRowHeight}
          rowRenderer={rowRenderer}
        />
      </RadioGroup>
    </FormControl>
  );
}

/* {items && items.map((option, index) => (
  <div key={index} />
))} */
