/* eslint-disable max-len */
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, IconButton } from '@mui/material';
import { Answer, Field } from 'store/states/forms/types';
import { Note } from './styled';
import { Label } from '../Label/Label';
import { NoteSelector } from '../NoteSelector/NoteSelector';

interface IFieldBoxImage {
  index: number | undefined;
  field: Field;
  answer: Answer;
  handleClickEdit: () => void;
}

export function FieldBoxWithImage({
  index, field, answer, handleClickEdit,
}: IFieldBoxImage) {
  return (
    <>
      <Note key={index}>
        <Box sx={{
          width: '100%',
        }}>
          <>
            <Label>
              {field?.description}
              {' '}
              <IconButton onClick={handleClickEdit}>
                <EditIcon />
              </IconButton>
            </Label>
          <NoteSelector type={field?.type} answer={answer?.value} />
          </>
        </Box>
      </Note>
      <Divider />
    </>
  );
}
