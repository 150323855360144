import { Answer, Field, Rule } from 'store/states/forms/types';

function isSameAnswer(answer:Answer, rule: Rule) {
  return answer.field_id === rule.field_id
      && answer.value === rule.value
}

function validateContainsSome(rules: Rule[], answers:Answer[]) {
  if (!rules.length) return true;

  return rules.some((rule) => answers.find((answer) => isSameAnswer(answer, rule)));
}

function validateContainsAll(rules: Rule[], answers:Answer[]) {
  if (!rules.length) return true;
  return rules.every((rule) => answers.find((answer) => isSameAnswer(answer, rule)));
}

export function verifyRules(field: Field, answers: Answer[]) {
  if (!field.rules) return true;
  const isValidContainsSome = validateContainsSome(
    field.rules.needContainsSome,
    answers,
  );

  const isValidContainsAll = validateContainsAll(
    field.rules.needContainsAll,
    answers,
  );

  return isValidContainsAll && isValidContainsSome;
}
